import gsap from 'gsap'
import { $, $$, pubsub } from '../tools/utils'

class BurgerMenu {
  constructor () {
    this.DOM = {
      layout: $('.burgerlayout'),
      menu: $('.menu'),
      close: $('.burgerlayout__close'),
      tweenable: $$('.burgerlayout .tween'),
      nav: $('.burgerlayout__nav > ul')
    }
  }

  init () {
    this.bindEvents()
  }

  bindEvents () {
    const { menu, close } = this.DOM

    menu.addEventListener('click', () => this.toggle(true))
    close.addEventListener('click', () => this.toggle(false))

    pubsub.suscribe(
      'sections:info',
      (info) => this.sectionsInfoHandler(info)
    )
  }

  sectionsInfoHandler (info) {
    this.renderMenuItems(info)
    this.setupLinks()
  }

  setupLinks () {
    const links = $$('.burgerlayout__nav a')

    links.forEach(
      (link) => link.addEventListener(
        'click',
        (e) => {
          e.preventDefault()

          this.toggle(false)
          pubsub.publish('scroll:to', e.currentTarget.dataset.navId)
        }
      )
    )
  }

  toggle (booleanControl) {
    const { layout, tweenable } = this.DOM

    const timeline = gsap.timeline()

    // Toggle layout
    timeline.to(layout, {
      autoAlpha: +booleanControl,
      duration: 0.5,
      ease: 'Cubic.easeOut'
    })

    // Entry coreography only if is entry state
    if (booleanControl) {
      timeline.fromTo(
        tweenable,
        {
          opacity: 0,
          x: -10
        },
        {
          opacity: 1,
          x: 0,
          duration: 0.5,
          stagger: 0.1,
          ease: 'Cubic.easeOut'
        },
        '-=0.25'
      )
    }
  }

  renderMenuItems (info) {
    const { nav } = this.DOM
    const markup = info
      .filter(({ isHidden }) => !isHidden)
      .reduce((markup, { id, title }, index) => {
        const digit = index < 9 ? '0' + (index + 1) : (index + 1)

        return markup + (/* html */`
          <li data-index="${digit}">
            <a href="#" data-nav-id="${id}">${title}</a>
          </li>
        `)
      }, '')

    nav.innerHTML = markup
  }
}

export { BurgerMenu }
