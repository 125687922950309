import LocomotiveScroll from 'locomotive-scroll'
import { $, pubsub } from '../tools/utils'

class Scroll {
  constructor () {
    this.DOM = {
      el: $('.wrapper')
    }
  }

  init () {
    this.setup()
    this.bindEvents()
  }

  setup () {
    const { el } = this.DOM

    this.scroll = new LocomotiveScroll({
      el,
      smooth: true,
      offset: [0, '100%']
    })
  }

  bindEvents () {
    this.scroll.on('scroll', (args) => this.scrollHandler(args))
    pubsub.suscribe(
      'scroll:update',
      () => this.scroll.update()
    )

    pubsub.suscribe('scroll:to', (id) => this.scrollTo(id))
  }

  scrollTo (id) {
    this.scroll.scrollTo(`[data-scroll-id="${id}"]`)
  }

  scrollHandler ({ currentElements }) {
    pubsub.publish('scroll:currentElements', currentElements)
  }
}

export { Scroll }
