import gsap from 'gsap'
import { $find } from '../../tools/utils'

class Audio {
  constructor (instance) {
    this.DOM = {
      audio: $find(instance, 'audio'),
      control: $find(instance, '.audio__ctrl'),
      progress: $find(instance, '.audio__progress path')
    }
  }

  init () {
    this.bindEvents()
  }

  bindEvents () {
    const { control, audio } = this.DOM

    control.addEventListener('click', () => this.togglePlay())
    audio.addEventListener('timeupdate', () => this.updateProgress());
    audio.addEventListener('ended', () => this.resetState())
  }

  togglePlay () {
    const { control, audio } = this.DOM

    control.classList.toggle('playing')
    
    const isPlaying = control.classList.contains('playing')
    audio[isPlaying ? 'play' : 'pause']()
  }

  updateProgress () {
    const { progress } = this.DOM
    const { currentTime, duration } = this.DOM.audio
    const progressValue = currentTime * 100 / duration
    progress.style.strokeDasharray = `${progressValue} 100`
  }

  resetState () {
    const { control, progress } = this.DOM
    const progressTween = { value: 100 }
    
    control.classList.remove('playing')
    gsap.to(progressTween, {
      value: 0,
      duration: 1,
      ease: 'Cubic.easeInOut',
      onUpdate: () => {
        progress.style.strokeDasharray = `${progressTween.value} 100`
      }
    })
  }
}

export { Audio }