import { $, pubsub } from '../../tools/utils'
import Canvas from './canvas'

export default class Form {
  constructor (props) {
    this.props = {
      data: {},
      ...props
    }

    this.state = {
      estado: '',
      localidad: '',
      currentEstadoData: {}
    }

    this.DOM = {
      form: $('.action__form'),
      estadoSelect: $('select#estado'),
      localidadSelect: $('select#localidad'),
      downloadButton: $('#cartel-save')
    }

    this.canvas = new Canvas()
  }

  init () {
    this.setup()
    this.bindEvents()

    this.canvas.init()

    // force <first ESTADO> && <first LOCALIDAD> as default cartel info
    const defaultData = this.props.data[0].localidades[0]
    this.renderData(defaultData)
  }

  bindEvents () {
    const { estadoSelect, localidadSelect, downloadButton } = this.DOM

    estadoSelect.addEventListener('change', () => this.estadoChangeHandler())
    localidadSelect.addEventListener('change', () => this.localidadChangeHandler())
    downloadButton.addEventListener('click', () => {
      const { downloadButton } = this.DOM
      downloadButton.href = this.canvas.getImageFile()
      downloadButton.download = 'cartel.jpg'
    })
  }

  estadoChangeHandler () {
    const { estadoSelect, localidadSelect } = this.DOM
    const isDefaultOption = estadoSelect.value === ''

    this.setState({
      estado: estadoSelect.value
    })

    this.setupLocalidadSelectValues(estadoSelect.value)
    localidadSelect[
      (isDefaultOption ? 'set' : 'remove') + 'Attribute'
    ]('disabled', true)
  }

  localidadChangeHandler () {
    const { localidadSelect } = this.DOM
    const { currentEstadoData: { localidades } } = this.state
    const isDefaultOption = localidadSelect.value === ''

    if (isDefaultOption) return

    const data = localidades.find(({ nombre }) => nombre === localidadSelect.value)
    this.renderData(data)
  }

  renderData (data) {
    this.canvas.render(data)
  }

  setup () {
    const { form } = this.DOM
    form.style.display = 'block'

    this.setupEstadoSelectValues()
  }

  setupEstadoSelectValues () {
    const { estadoSelect } = this.DOM
    let markup = '<option value="">Selecciona un estado</option>'

    this.props.data.forEach(({ estado }) => {
      markup += /* html */`<option value="${estado}">${estado}</option>`
    })

    estadoSelect.innerHTML = markup
  }

  setupLocalidadSelectValues (estadoKey) {
    const { localidadSelect } = this.DOM
    const estado = this.props.data.find(({ estado }) => estado === estadoKey)
    let markup = '<option value="">Selecciona una localidad</option>'

    if (estado !== undefined) {
      this.setState({ currentEstadoData: estado })
      estado.localidades.forEach(({ nombre }) => {
        markup += /* html */`<option value="${nombre}">${nombre}</option>`
      })
    }

    pubsub.publish('resetLocalidad')
    localidadSelect.innerHTML = markup
  }

  setState (newState) {
    this.state = {
      ...this.state,
      ...newState
    }
  }

  resize () {
    this.canvas.resize()
  }
}
