import { debounce } from 'lodash'
import ModuleManager from './moduleManager'
import Cartel from './modules/cartel'
import FakeSelectController from './modules/fakeSelect'
import { $, $$, isDesktop, isMobileDevice } from './tools/utils'

import { Scroll } from './modules/scroll'
import { Nav } from './modules/nav'
import { BurgerMenu } from './modules/burgermenu'
import { Sections } from './modules/sections'
import { AudioController } from './modules/audio'
import { Splash } from './modules/splash'

window.addEventListener('load', () => {
  /**
   * Notify environment to the DOM
   */
  if (isMobileDevice()) $('body').classList.add('mobile-device')
  if (isDesktop()) $('body').classList.add('desktop-device')

  /**
   * Iframe responsive URL validation
   */
  $$('.responsive-src').forEach(element => {
    const { srcMobile, srcDesktop } = element.dataset
    element.setAttribute('src', isMobileDevice() ? srcMobile : srcDesktop)
  });

  /**
   * Module manager setup
   */
  const moduleCatalogSetup = {
    general: [
      new Splash(),
      new Cartel(),
      new FakeSelectController(),
      new Scroll(),
      new Nav(),
      new BurgerMenu(),
      new Sections(),
      new AudioController()
    ]
  }

  const moduleManager = new ModuleManager(moduleCatalogSetup)
  moduleManager.init()

  /**
   * Bind events global control
   */
  window.onresize = debounce(() => {
    moduleManager.resizeHandler()
  }, 100)
})
