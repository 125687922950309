import { pubsub, $$ } from "../tools/utils"

class Sections {
  constructor () {
    this.DOM = {
      sections: $$('[data-scroll-id]')
    }
  }

  init () {
    this.setupInfo()
    this.emitInfo()
  }

  setupInfo () {
    const { sections } = this.DOM

    this.sectionsInfo = sections.map((section) => {
      const { scrollId: id, title, hidden: isHidden } = section.dataset
      return { id, title, isHidden }
    })
  }

  emitInfo () {
    pubsub.publish('sections:info', this.sectionsInfo)
  }
}

export { Sections }
