import { $$ } from '../../tools/utils'
import { Audio } from './audio'

class AudioController {
  constructor () {
    this.DOM = {
      instances: $$('.audio')
    }
  }

  init () {
    this.DOM.instances.forEach(
      (instance) => new Audio(instance).init()
    )
  }
}

export { AudioController }
