import gsap from 'gsap'
import { $, $$, $find, isMobileDevice, pubsub } from '../tools/utils'

class Nav {
  constructor () {
    this.DOM = {
      sections: $$('[data-scroll-id]'),
      nav: $('.nav__list'),
      links: null
    }
    this.activeSectionId = null
  }

  init () {
    this.bindEvents()
  }

  bindEvents () {
    pubsub.suscribe(
      'scroll:currentElements',
      (currentElements) => this.updateProgress(currentElements)
    )

    pubsub.suscribe(
      'sections:info',
      (info) => this.onSectionsInfoHandler(info)
    )
  }

  onSectionsInfoHandler (info) {
    this.sectionsInfo = info
    this.renderSections()
    this.setupLinks()
  }

  setupLinks () {
    const links = $$('.nav__card')

    links.forEach(
      (link) => link.addEventListener(
        'click',
        (e) => {
          e.preventDefault()
          pubsub.publish('scroll:to', e.currentTarget.dataset.navId)
        }
      )
    )
  }

  updateProgress (currentElements) {
    this.currentElements = currentElements
    const currentSection = this.sectionsInfo.find((section) => !!currentElements[section.id])
    let isSectionChanged = false
    let prevSectionId = null

    if (currentSection === undefined) return

    const { id } = currentSection

    if (this.activeSectionId !== id) {
      isSectionChanged = true
      prevSectionId = this.activeSectionId
      this.activeSectionId = id
      this.setDOMActiveSection()
    }

    const currentNavItem = $(`#${id}`)

    if (currentNavItem === null) return

    const { progress } = currentElements[id]
    const progressElement = currentNavItem.querySelector('.nav__progress-fill')

    gsap.to(
      progressElement,
      isMobileDevice()
        ? { scaleX: progress }
        : { scaleY: progress }
    )
    .eventCallback('onStart', () => progressElement.progress = progress)

    // Force complete progress 0/1 on prev section
    if (isSectionChanged && prevSectionId !== null) this.forceCompleteProgress(prevSectionId)
  }

  forceCompleteProgress (id) {
    const navItem = $(`#${id}`)

    if (navItem === null) return

    const progressElement = $find(navItem, '.nav__progress-fill')
    const progressValue = +(progressElement.progress > 0.5)
    
    gsap.to(
      progressElement,
      isMobileDevice()
        ? { scaleX: progressValue }
        : { scaleY: progressValue }
    )
  }

  setDOMActiveSection () {
    const activeDOM = $('.nav__item.active')
    const newDOM = $(`#${this.activeSectionId}`)

    activeDOM && activeDOM.classList.remove('active')
    newDOM && newDOM.classList.add('active')

    isMobileDevice() && this.mobileDOMActiveMethod(activeDOM, newDOM)
  }

  mobileDOMActiveMethod (activeDOM, newDOM) {
    if (activeDOM !== null) {
      gsap.to(activeDOM, {
        y: -10,
        autoAlpha: 0,
        duration: 0.1,
        onStart: () => activeDOM.classList.add('tweening'),
        onComplete: () => activeDOM.classList.remove('tweening')
      })
    }

    if (newDOM === null) return
    gsap.fromTo(
      newDOM,
      { y: 10, autoAlpha: 0 },
      {
        y: 0,
        autoAlpha: 1,
        duration: 0.1,
        delay: 0.1,
        onStart: () => activeDOM.classList.add('tweening'),
        onComplete: () => activeDOM.classList.remove('tweening')
      }
    )
  }

  renderSections () {
    const { nav } = this.DOM
    const markup = this.sectionsInfo
      .filter(({ isHidden }) => !isHidden)
      .reduce((markup, { id, title }, index) => {
        const digit = index < 9 ? '0' + (index + 1) : (index + 1)

        return markup + (/* html */`
          <li class="nav__item" id="${id}">
            <div class="nav__progress">
              <span class="nav__progress-fill"></span>
            </div>
            <div class="nav__number">
              <span class="nav__number-digit">${digit}</span>
            </div>
            <a href="#" class="nav__card" data-nav-id="${id}">
              <span class="nav__card-inner">
                <h2 class="nav__card-title">${title}</h2>
                <span class="nav__card-digit">${digit}</span>
              </span>
            </a>
          </li>
        `)
      }, '')

    nav.innerHTML = markup
  }
}

export { Nav }
