import gsap from "gsap/all"
import { $, $$ } from "../tools/utils"

class Splash {
  constructor () {
    this.DOM = {
      element: $('.splash'),
      logos: $$('.splash img'),
      loader: $('.splash .loader')
    }
  }

  init () {
    gsap.timeline()
      .to([...this.DOM.logos, this.DOM.loader], {
        opacity: 0,
        duration: 0.5,
        ease: 'Cubic.easeInOut'
      })
      .to(this.DOM.element, {
        autoAlpha: 0,
        duration: 1,
        ease: 'Cubic.easeInOut'
      })
  }
}

export { Splash }